@tailwind utilities;

@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('../public/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('../public/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('../public/fonts/MarkPro-Black.ttf') format('truetype');
}

.fourtile {
  width: 23.333333%;
}

.video-container {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.caretIcon1 {
  //border-top: .3em dashed;
  display: inline-block;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  width: 0;
  border-top: .3em solid \9;
  border-left: .3em solid transparent;
  border-right: .3em solid transparent;
}
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-info {
  display: flex;
  align-items: center;
}

.pagination-info select {
  margin-left: 10px;
  padding: 5px;
}

.pagination select {
  padding: 10px 15px;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  cursor: pointer;
}

.pagination select {
  width: auto;
  background-color: #fff;
}

.pagination-controls button {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-controls {
  display: flex;
  gap: 10px;
}

.pagination-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
}

.pagination-button:disabled {
  cursor: not-allowed;
}

.disabled-icon {
  display: none;
  position: absolute;
  right: 5px;
  font-size: 18px;
  color: #6b6c7e;
}

.pagination-button:disabled:hover .disabled-icon {
  display: inline;
}

@media (max-width: 768px) {
  .fisrt-button {
    display: none;
  }
}
@media (max-width: 768px) {
  .richTextTable {
    margin-left: 10rem;
  }
}
/*  */
/* Table.css */

/* .pl-4 {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.pl-4.active {
  max-height: 500px; 
} */

.custom-select {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.custom-select > span {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
  min-width: 120px;
  text-align: center;
}

.custom-select > ul {
  position: absolute;
   background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  min-width: 100%;
}


.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}
.custom-select > ul li {
  padding: 8px 16px;
  cursor: pointer;
}

.custom-select > ul li:hover {
  background-color: #f2f2f2;
}

.termsUnorderedList {
  list-style-type: disc;
  padding-left: 1rem;
}

.termsUnorderedList ul{
    list-style-type: circle;
    padding-left: 2rem;
  }
 
  .termsUnorderedList ul ul{
    list-style-type: square;
    padding-left:3rem;
  }
 
  .termsUnorderedList ul ul ul{
    list-style-type:disc;
    padding-left:4rem;
  }

  .termsOrderedList {
    list-style-type: decimal;
    padding-left: 1rem;
}
 
   .termsOrderedList ol {
    list-style-type: lower-alpha;
    padding-left: 2rem;
}
 
 
.termsOrderedList ol ol {
    list-style-type: lower-roman;
    padding-left:3rem;
}
 
.termsOrderedList ol ol ol {
    list-style-type: upper-alpha;
    padding-left:4rem;
}


.online-mobile-terms-termsOrderedList{ 
    list-style-type: none;
    counter-reset: item;
  }
  
.online-mobile-terms-termsOrderedList li {
    display: table;
    counter-increment: item;
  }
  
.online-mobile-terms-termsOrderedList  li:before {
    content: counters(item, ".") '.';
    display: table-cell;
    padding-right: 0.2rem;
  }

.online-mobile-terms-termsOrderedList ol ol li:before {
    content: counter(item, lower-alpha) ".";
    padding-left: 1rem;
  }

  .online-mobile-terms-termsOrderedList ol ol ol li:before {
    content: counter(item, lower-roman) ".";
    padding-left: 1rem;
  }

/* Landscape orientation (iPad Mini 5/6) */
@media (max-width: 1080px) {
  /* Apply styles for iPad Mini in landscape mode */
  .your-element {
    font-size: 18px;
  }
}

.unorderedList{
  list-style-type: disc;
  padding-left: 2rem;
}
.orderedList {
  list-style-type: decimal;
  padding-left: 1rem;
}

 .orderedList ol {
  list-style-type: lower-alpha;
  padding-left: 2rem;
}


.orderedList ol ol {
  list-style-type: lower-roman;
  padding-left:3rem;
}

.orderedList ol ol ol {
  list-style-type: upper-alpha;
  padding-left:4rem;
}
.sitemapUL {
  list-style-type: disc;
  padding-left: 1rem;
}

.sitemapUL ul{
    list-style-type: circle;
    padding-left: 2rem;
  }
 
  .sitemapUL ul ul{
    list-style-type: square;
    padding-left:2rem;
  }
 
  .sitemapUL ul ul ul{
    list-style-type:square;
    padding-left:2rem;
  }

  .sitemapUL ul ul ul ul{
    list-style-type:square;
    padding-left:2rem;
  }

  .sitemapUL li::marker {
    font-size:20px;
    line-height:20px;
}
/* //tailwind css */

@screen lg {
  .promotional-callout-carousel {
    & .slick-arrow {
      &.slick-prev,
      &.slick-next {
        @apply block h-12 w-12 z-10;
      }
      &.slick-prev {
        left: -3.5rem;
        top: 50%;
      }
      &.slick-next {
        right: -3.5rem;
        top: 50%;
      }
    }
    & .slick-slide {
      &.slick-active:nth-of-type(1) {
        @apply pl-0;
      }
    }
  }
}
.bg-sky-amy-crisp-gradient-from-top {
  background-image: linear-gradient(180deg, #f58b73 0%, #9268ac 100%);
}

.slider-container {
  max-width: 100vw;
  width: 100%;
}

.more-choice-for-you-section-carousel {
  @screen md {
    padding-left: 0;
    padding-right: 0;
  }
}

.more-choice-for-you-section-carousel .slick-track {
  @apply flex pt-12 ml-0 mr-0;
}

.more-choice-for-you-section-carousel .slick-track .slick-slide {
  height: inherit !important;
}

.more-choice-for-you-section-carousel .slick-track .slick-slide > div,
.more-choice-for-you-section-carousel .slick-track .slick-slide > div > div {
  @apply h-full;
}

.more-choice-for-you-section-carousel .slick-arrow {
  @apply h-12 w-12 z-10;
}

.more-choice-for-you-section-carousel .slick-arrow {
  @apply h-12 w-12 z-10;
}

.more-choice-for-you-section-carousel .slick-arrow:before {
  content: none;
}

.more-choice-for-you-section-carousel.slick-prev {
  left: -56px;
  top: 50%;
}

.more-choice-for-you-section-carousel.slick-next {
  right: -56px;
  top: 50%;
}

.generic-promotional-card p > a {
  text-decoration: underline;
}

/* New Navbar Styles */
@media screen and (max-width: 1111px) {
  .navbar-width-1 {
    width: 699px;
  }
}

@media screen (max-width: 1440px) {
  .navbar-width-2 {
    width: 979px;
  }
}

@media screen and (min-width: 1111px) and (max-width: 1439px) {
  .breakpoint-1 {
    min-width: 699px;
    max-width: 978px;
    width: max-content;
    gap: 24px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 2000px) {
  .breakpoint-2 {
    min-width: 979px;
    max-width: 1507px;
    gap: 32px;
  }
}

@media screen and (min-width: 2000px) {
  .breakpoint-3 {
    min-width: 1507px;
    max-width: 1600px;
    gap: 48px;
  }
}

@media screen and (max-width: 1110px) {
  .breakpoint-4 {
    display: none;
  }
}

.new-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  @apply breakpoint-1 breakpoint-2 breakpoint-3 breakpoint-4 navbar-width-1 navbar-width-2;
}

.truncate-overflow {
  position: relative;
  overflow: hidden;
}

.truncate-overflow::before {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 39px;
  z-index: 2;
}

.truncate-overflow::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 52px;
  height: 22.5px;
  background: black;
}

/* New BB Showcase Mobile DropDown */

.showcase-dropdown {
  padding: 16px 24px;
  background: #f2f2f2;
  font-size: 16px;
  line-height: 1.2;
  font-family: MarkPro-Bold sky;
}

.showcase-dropdown-item{
  padding: 16px 24px;
  background: #f2f2f2;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
  font-family: MarkPro-Bold sky;
  border-radius: 0 0 24px 24px;
  border-top: 1px solid #c5c5c5;
  width: 100%;
}

.showcase-dropdown-item > div > div {
  max-width: 277px;
  height: 0px;
  border: 1px solid #c5c5c5;
  margin: 0 auto;
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev{
  z-index: 5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.md\:container {
  max-width: 1024px !important;
}
/* BB call support banner styles */

.fr-paragraph.fr-paragraph-text-size-s {
  font-size: 1.125rem;
}

.fr-paragraph.fr-paragraph-text-align-center {
  text-align: center;
}

.fr-paragraph.fr-paragraph-text-font-markprobold {
  font-family: 'MarkPro-Bold SKY';
}

.fr-paragraph.fr-paragraph-text-color-white {
  color: #fff;
}

.iframe-wrapper {
  width: 100%;
  aspect-ratio: 1023/575;
  overflow: hidden;
}
.promo-container {
  margin: 20px 60px 40px 40px;
  flex: 1;
}
@media (min-width: 1024px) {
  .promo-container {
    max-width: 1100px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .promo-container {
    max-width: 588px;
  }
}
@media (max-width: 767px) {
  .promo-container {
    max-width: 375px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.tvc-bundle-carousel .slick-list {
  padding-top: 30px;
  overflow-x: hidden !important;
}
@media (min-width: 767px) {
  .tvc-bundle-carousel {
    padding-left: 30px;
  }
}

.tvc-bundle-carousel .slick-arrow.slick-prev:before,
.tvc-bundle-carousel .slick-arrow.slick-next:before {
  content: none;
}
.tvc-bundle-carousel .slick-disabled {
  opacity: 0.1;
}
.tvc-bundle-carousel .slick-dots li {
  width: 10px;
}
.tvc-bundle-carousel .slick-track .slick-slide {
  width: inherit !important;
}

.tvc-bundle-carousel .slick-dots li.slick-active button:before {
  color: #0057ff;
  font-size: 20px;
  width: 10px;
  height: 22px;
  opacity: 1;
}
/* for BB banner text */
.banner_font_bold {
  font-size: 18px;
  font-family: 'MarkPro-Black SKY';
  line-height: 1.2;
}
.w-325px {
  width: 325px;
}
.w-920px {
  width: 920px;
}
.w-800px {
  width: 800px;
}
.margin-120{
  margin-left: 40px;
  margin-right: 40px;
}
@media (min-width: 1024px) {
  .margin-120{
  margin-left: 120px;
  margin-right: 120px;
}
}

.top-96{
 top:24rem
}

.left-20{
  left:20rem
}

.sky-bg-daylight:hover{
  --bg-opacity: 1;
  background-color: #00013A;
  background-color: rgba(0, 1, 58, var(--bg-opacity));
}

.rolling-banner-carousel .custom-carousel .slick-dots {
  z-index: 1;
  bottom: 1rem;
  top:"40rem"
}
.rolling-banner-height {
  height:"50rem"
}

.imageOnly {
  width:"182rem"
}

@media (max-width: 768px) {
  .submenu-container {
    display: flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory; /* Enable horizontal snapping */
    width: 100%;
  }
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #00013a !important;
  font-family: 'Arial', sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #00013a;
}

.embeddedServiceHelpButton .uiButton {
  margin: -20px 0px 0px 0px !important;
  min-width: auto !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  border-radius: 8px !important;
}

.embeddedServiceHelpButton .embeddedServiceIcon::before {
  font-size: 2.25em !important;
}

.embeddedServiceHelpButton .embeddedServiceIcon {
  margin-right: 0px !important;
}

.embeddedServiceHelpButton .helpButtonDisabled {
  display: none !important;
}

@media (max-width: 767px) {
  .embeddedServiceHelpButton .uiButton {
    margin: -75px 0px 0px 100px !important;
  }

  .embeddedServiceHelpButton .embeddedServiceIcon::before {
    font-size: 3.25em !important;
  }
}
.embeddedServiceHelpButton .helpButton .uiButton {
  border-radius: 100px !important;
 display: flex !important;
 flex-direction: row-reverse !important;
 background: #00013a !important;
 width: 103px !important;
  height: 48px !important;
  padding: 14px 16px !important;
  font-size: 10px !important;
font-weight: 700 !important;
line-height: 125% !important;
font-family: "Mark Pro" !important;
font-style: normal !important;
}
.embeddedServiceHelpButton .helpButton {

  border-radius: 100px !important;
  bottom:20px !important;
}
.embeddedServiceHelpButton {
  position: fixed !important;
   left: 85% !important;


          /* right: 40px !important; */

  }
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message{

 font-size: 0px !important;
}
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message::after{
 content: 'Chat';
font-size: 16px !important;
font-weight: 700 !important;
line-height: 125% !important;
font-family: "Mark Pro" !important;
font-style: normal !important;
}

@media (max-width: 520px) {
  .embeddedServiceHelpButton .helpButton .uiButton {
    padding: 8px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
    border-radius: 10px 10px 0px 0px !important;
    text-align: center !important;
 font-family: "Mark Pro" !important;
 font-size: 1em !important;
 font-style: normal !important;
 font-weight: 700 !important;
 line-height: 125% !important;
    flex-direction: row !important;

  }
  .embeddedServiceHelpButton .helpButton {

   transform: rotate(-90deg) !important;
   display: inline-flex !important;
   bottom: 3vh !important;
 position: fixed !important;
 left:  92% !important;
 width: 164px !important;
 height: 40px !important;


 }
  .embeddedServiceHelpButton .uiButton .helpButtonLabel .message{
    overflow: auto !important;


  }
  .embeddedServiceHelpButton .uiButton .helpButtonLabel{
    overflow: auto !important;
    transform: rotate(90deg);
  }
  .embeddedServiceHelpButton .uiButton .embeddedServiceIcon {
    transform: rotate(90deg);
  }

}
@media only screen and (min-width: 520px) and (max-width: 1024px){
  .embeddedServiceHelpButton .helpButton .uiButton{
    padding: 14px 16px !important;
  }


  }
  @media only screen and (min-width: 325px) and (max-width: 1440px){

    .embeddedServiceHelpButton .embeddedServiceIcon::before {
      font-size: 24px !important;
    }
    .embeddedServiceHelpButton .embeddedServiceIcon{
      font-size: 24px !important;
    }
    }

    .section-card-panel h2 {
      font-size: 52px !important;
      line-height: 60px !important;
      font-weight: 900 !important;
      font-family: 'MarkPro-Black SKY' !important;
    }
    
    @media screen and (max-width: 768px) {
      .section-card-panel h2 {
        font-size: 36px !important;
        line-height: 40px !important;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
      }
    }
    
    /* .section-card-panel h2 b {
      background-image: linear-gradient(110.93deg, #F26522 0%, #F96DB1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    } */

    .product-slider .slick-track {
      display: flex;
    }
    
    .product-slider .slick-slide {
      height: inherit !important;
      margin-right: 0px;
    }
    .product-slider .slick-disabled {
      opacity: .1;
    }
    .product-slider .slick-slide > div,
    .product-slider .slick-slide > div > div,
    .product-slider .slick-slide > div > div > div {
      height: 100%;
    }
    
    .large-gallery .slick-dots button::before {
      color: white !important;
      font-size: 10px;
      margin-top: 10px;
    }
    
    .large-gallery .slick-slide {
      height: 1300px;
    }